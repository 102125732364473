<template>
	<div class="pay">
		<div class="operation">
			<!-- 提交表单 -->
			<el-form v-model="form" :inline="true">
				<el-form-item label="园区:">
					<el-select v-model="form.name" placeholder="请选择" size="mini">
						<el-option v-for="item in resData" :key="item.id" :label="item.building_name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="房号:">
					<el-input type="text" size="mini" v-on:keydown.enter="submitForm" v-model="form.house_name" placeholder="如 1-201"></el-input>
				</el-form-item>
				<el-form-item label="姓名:">
					<el-input type="text" size="mini"  v-on:keydown.enter="submitForm"  v-model="form.user_name"></el-input>
				</el-form-item>
				<el-form-item label="月份:">
					<el-date-picker v-model="form.date" type="month" placeholder="选择日期" size="mini"></el-date-picker>
				</el-form-item>
				<el-form-item label="状态:">
					<el-select v-model="form.status" size="mini">
						<el-option v-for="item in statusData" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm" size="mini">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="secondRow">
				<div class="left">
					<el-button size="mini" @click="exportJiaofei"
						v-if="Permission1">
						<i class="el-icon-plus"></i>生成物业缴费记录
					</el-button>

					<el-dropdown @command="jiaofeifabu" v-if="Permission1">
						<el-button size="mini">
							<i class="el-icon-edit"></i>
							缴费发布后
							<i class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<template #dropdown>
							<el-dropdown-menu>
								<el-dropdown-item command="a">批量发布</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</div>
				<div class="right">
					<el-form inline="true">
						<el-form-item>
							<el-input v-model="orderinput"  v-on:keydown.enter="search" size="mini" placeholder="请输入单据号"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button size="mini" type="success" @click="search"><i class="el-icon-search"></i> 单据号查询
							</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>

		<hr />
		<div class="operation2" >
			<!-- <el-button size="mini"> <i class="el-icon-plus"></i>超小按钮</el-button> -->
			<el-dropdown @command="importDaikou" v-if="Permission1">
				<el-button size="mini">
					<i class="el-icon-edit"></i>
					代扣
					<i class="el-icon-arrow-down el-icon--right"></i>
				</el-button>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="a">代扣失败格式</el-dropdown-item>
						<el-dropdown-item command="c">代扣成功格式</el-dropdown-item>
						<el-dropdown-item command="b">代扣失败导入</el-dropdown-item>
						<el-dropdown-item command="d">代扣成功导入</el-dropdown-item>
						<el-dropdown-item command="e">代扣记录导出</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
			<el-dropdown @command="exportYijf" v-if="Permission1">
				<el-button size="mini">
					<i class="el-icon-edit"></i>
					已缴费导出
					<i class="el-icon-arrow-down el-icon--right"></i>
				</el-button>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="a">已缴费导出(月)</el-dropdown-item>
						<el-dropdown-item command="b">已缴费导出(天)</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
			<el-dropdown @command="importXianjin" v-if="Permission2">
				<el-button size="mini">
					<i class="el-icon-edit"></i>
					现金缴费
					<i class="el-icon-arrow-down el-icon--right"></i>
				</el-button>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="c" :disabled="Permission2Dis" >现金缴费</el-dropdown-item>
						<el-dropdown-item command="a" :disabled="Permission1Dis">
							批量现金缴费格式</el-dropdown-item>
						<el-dropdown-item command="b" :disabled="Permission1Dis">
							批量现金缴费导入</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
			<!-- 水费导入 -->
			<el-dropdown @command="importWater" v-if="Permission1">
				<el-button size="mini">
					<i class="el-icon-edit"></i>
					水费导入
					<i class="el-icon-arrow-down el-icon--right"></i>
				</el-button>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="a">导入格式</el-dropdown-item>
						<el-dropdown-item command="b">水费导入（覆盖模式）</el-dropdown-item>
						<el-dropdown-item command="c">水费导入（相加模式，+加-减，发布后）</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
			<!-- 公摊电费导入 -->
			<el-dropdown @command="importDianfei" v-if="Permission1">
				<el-button size="mini">
					<i class="el-icon-edit"></i>
					公摊电费导入
					<i class="el-icon-arrow-down el-icon--right"></i>
				</el-button>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="a">导入格式</el-dropdown-item>
						<el-dropdown-item command="b">公摊电费导入</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>

			<el-dropdown @command="importYujiaoWuye" v-if="Permission1">
				<el-button size="mini">
					<i class="el-icon-edit"></i>
					预交物业费
					<i class="el-icon-arrow-down el-icon--right"></i>
				</el-button>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="a">预交物业费导入格式</el-dropdown-item>
						<el-dropdown-item command="b">预交物业费导入</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
			<el-dropdown @command="importZengsongWuye" v-if="Permission1">
				<el-button size="mini">
					<i class="el-icon-edit"></i>
					赠送物业费
					<i class="el-icon-arrow-down el-icon--right"></i>
				</el-button>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="a">赠送物业费格式</el-dropdown-item>
						<el-dropdown-item command="b">赠送物业费导入</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
			<!-- <el-dropdown @command="jiaofeifabu">
        <el-button size="mini">
          <i class="el-icon-edit"></i>
          缴费发布
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="a">批量发布</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown> -->
			<el-dropdown @command="importYujiao" v-if="Permission2">
				<el-button size="mini">
					<i class="el-icon-edit"></i>
					预缴费
					<i class="el-icon-arrow-down el-icon--right"></i>
				</el-button>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="a" :disabled="Permission1Dis">
							预缴费导入格式</el-dropdown-item>
						<el-dropdown-item command="d" :disabled="Permission2Dis">手动录入预缴费</el-dropdown-item>
						<el-dropdown-item command="b" :disabled="Permission1Dis">
							预缴费导入(发布)</el-dropdown-item>
						<el-dropdown-item command="c" :disabled="Permission1Dis">
							预缴费导入(覆盖)</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>

			<el-dropdown @command="importResrtFree" v-if="Permission1">
				<el-button size="mini">
					<i class="el-icon-edit"></i>
					重置缴费
					<i class="el-icon-arrow-down el-icon--right"></i>
				</el-button>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="a" >
							重置缴费导入格式</el-dropdown-item>
						<el-dropdown-item command="b" >
							重置缴费导入</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
			<el-dropdown @command="paySend" v-if="Permission1">
				<el-button size="mini">
					<i class="el-icon-edit"></i>
					通知发送
					<i class="el-icon-arrow-down el-icon--right"></i>
				</el-button>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="a">缴费成功通知发送</el-dropdown-item>
						<el-dropdown-item command="b">未缴费提醒</el-dropdown-item>
						<el-dropdown-item command="c">银行代扣失败提醒</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
			<el-button size="mini" @click="printFn" v-if="Permission2">
				<i class="el-icon-plus"></i>打印
			</el-button>
			<el-button size="mini" @click="qiantai" v-if="Permission1">
				<i class="el-icon-plus"></i>导出
			</el-button>

		</div>

		<!-- 列表 -->
		<el-table :data="tableData" border highlight-current-row max-height="750" @current-change="handleChange">
			<el-table-column prop="index" label="编号" width="60"> </el-table-column>
			<el-table-column prop="id" label="id" width="70"> </el-table-column>
			<el-table-column prop="room_name" label="房号" width="125">
			</el-table-column>
			<el-table-column prop="user_name" label="业主姓名" width="100">
			</el-table-column>
			<el-table-column prop="month" label="月份" width="80"> </el-table-column>
			<el-table-column prop="property_fee" label="物业费" width="70">
			</el-table-column>
			<el-table-column prop="wood_fee" label="柴房物业费" width="70">
			</el-table-column>
			<el-table-column prop="fee" label="公摊电费" width="50">
			</el-table-column>
			<el-table-column prop="health_costs" label="卫生费" width="50">
			</el-table-column>
			<el-table-column prop="maintenance_fee" label="维修基金" width="70">
			</el-table-column>
			<el-table-column prop="water" label="水费" width="60"> </el-table-column>
			<el-table-column prop="totalHe_fee" label="合计" width="80">
			</el-table-column>
			<el-table-column prop="yj_fee" label="预交物业费" width="80">
			</el-table-column>
			<el-table-column prop="zs_fee" label="赠送物业费" width="50">
			</el-table-column>
			<el-table-column prop="dk_wuye_fee" label="代扣物业费" width="50">
			</el-table-column>
			<el-table-column prop="dk_water_fee" label="代扣水费" width="50">
			</el-table-column>
			<el-table-column prop="status" label="状态" width="50"> </el-table-column>
			<el-table-column prop="total_fee" label="缴费金额" width="50">
			</el-table-column>
			<el-table-column prop="transaction_id" label="支付订单号" width="100">
			</el-table-column>
			<el-table-column prop="pay_time" label="支付时间" width="180">
			</el-table-column>
			<el-table-column prop="create_time" label="创建时间" width="180">
			</el-table-column>
		</el-table>
		<div class="block">
			<el-pagination background @current-change="handlePagination" v-model:currentPage="currentPage1"
				:page-size="50" layout="total, prev, pager, next" :total="totalCount">
			</el-pagination>
		</div>
	</div>
	<!-- 关于月份的导入 -->
	<el-dialog :title="importTitle" v-model="jiaofeiImportModel" style="width: 500px">
		<!-- <div
      v-show="importTitle == '生成物业缴费记录'"
      style="color: red; margin-bottom: 20px"
    >
      只能生成当月的缴费记录，并且只能生成一次。
    </div> -->

		<el-date-picker v-model="jiaofeijilu" type="month" placeholder="选择月份" v-if="importTitle == '生成物业缴费记录'">
		</el-date-picker>

		<el-date-picker v-model="importDatePiliang" type="month" placeholder="选择日期" v-else></el-date-picker>

		<template #footer>
			<span class="dialog-footer">
				<el-button @click="jiaofeiImportModel = false">取 消</el-button>
				<el-button @click="submit" type="primary">生成</el-button>
			</span>
		</template>
	</el-dialog>

	<!-- 导入文件 -->
	<el-dialog :title="importTitle" v-model="fileImportModel">
		<div class="waring" v-if="importTitle == '水费批量导入并发布'">
			注意：请上传正确格式的Excel
			文件，注意请确保导入的数据正确,水费导入后记录会自动发布。
		</div>
		<div class="waring" v-else-if="importTitle == '预交物业费批量导入'">
			注意：请上传正确格式的Excel
			文件，注意预交费的记录,必须系统已经生成,并且数据的状态是未缴费。
		</div>
		<div class="waring" v-else-if="importTitle == '赠送物业费批量导入'">
			注意：请上传正确格式的Excel
			文件，注意赠送的物业费,必须系统已经生成,并且数据的状态是未缴费。
		</div>
		<div class="waring" v-else-if="importTitle == '预交费批量导入(发布)'">
			注意：预缴费功能主要用于数据发布后，某些用户只交一部分费用的情况。如果全部缴清请使用现金缴费功能。
		</div>
		<div class="waring" v-else-if="importTitle == '预交费批量导入(清空)'">
			注意：预缴费清空功能主要用于数据发布后，数据错误而清空的操作。
		</div>
		<div class="waring" v-else-if="importTitle == '银行代扣失败导入'">
			注意：请上传正确格式的Excel 文件
		</div>
		<div class="waring" v-else-if="importTitle == '现金缴费数据批量导入'">
			注意：请上传正确格式的Excel
			文件，注意现金缴费的记录,必须系统已经生成,并且数据的状态是已发布。
		</div>
		<div class="waring" v-else-if="importTitle == '公摊电费导入'">
			注意：请上传正确格式的Excel
			文件
		</div>
		<div class="waring" v-else-if="importTitle == '重置缴费导入'">
			注意：请上传正确格式的Excel
			文件,注意一旦上传将重置缴费金额与订单且无法恢复数据，请谨慎上传
		</div>
		<input class="upload" type="file" ref="upload" accept="MIME_type" @change="uploadFile($event)" />

		<template #footer>
			<span class="dialog-footer">
				<el-button @click="fileImportModel = false">取 消</el-button>
			</span>
		</template>
	</el-dialog>

	<!-- 关于天的单独导出 -->
	<el-dialog :title="'已缴费导出(天)'" v-model="jiaofeiImportModelDay">
		<el-form :model="jiaofeiExportDay" ref="ExportDayForm" label-width="100px" :rules="exportDayRules"
			:validate-on-rule-change="true">
			<el-form-item label="日  期:" class="exportStyle" prop="exportDay">
				<el-date-picker v-model="jiaofeiExportDay.exportDay" type="date" placeholder="选择日期"></el-date-picker>
			</el-form-item>
			<el-form-item label="缴费方式:" class="exportStyle" prop="way">
				<el-select v-model="jiaofeiExportDay.way" placeholder="请选择">
					<el-option v-for="item in ExportDayWay" :key="item.id" :label="item.method" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="状  态:" class="exportStyle" prop="status">
				<el-select v-model="jiaofeiExportDay.status">
					<el-option v-for="item in ExportDayStatus" :key="item.id" :label="item.status" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
		</el-form>

		<template #footer>
			<span class="dialog-footer">
				<el-button @click="jiaofeiImportModelDay = false">取 消</el-button>
				<el-button @click="submitDay" type="primary">生成</el-button>
			</span>
		</template>
	</el-dialog>
	<!-- 前台导出弹框 -->

	<el-dialog title='导出 / 预缴费 / 赠送缴费 / 预缴物业费' v-model="qiantaiModel">
		<el-form :model="qiantaiForm" ref="qiantaiform" label-width="100px" :rules="qiantaiRules"
			:validate-on-rule-change="true">
			<el-form-item label="日  期:" class="exportStyle" prop="qiantaiMonth">
				<el-date-picker v-model="qiantaiForm.qiantaiMonth" type="month" placeholder="选择日期"></el-date-picker>
			</el-form-item>
			<el-form-item label="导出类型:" class="exportStyle" prop="way">
				<el-select v-model="qiantaiForm.way" placeholder="请选择">
					<el-option v-for="item in qiantaiExportMonthWay" :key="item.id" :label="item.method"
						:value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="qiantaiModel = false">取 消</el-button>
				<el-button @click="submitQiantai" type="primary">生成</el-button>
			</span>
		</template>
	</el-dialog>
	<!-- 关于导出月的单独弹出 -->
	<el-dialog :title="'已缴费导出(月)'" v-model="jiaofeiImportModelMonth">
		<el-form :model="jiaofeiExportMonth" ref="ExportMonthForm" label-width="100px" :rules="exportMonthRules"
			:validate-on-rule-change="true">
			<el-form-item label="日  期:" class="exportStyle" prop="exportMonth">
				<el-date-picker v-model="jiaofeiExportMonth.exportMonth" type="month" placeholder="选择日期">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="缴费方式:" class="exportStyle">
				<el-select v-model="jiaofeiExportMonth.way" placeholder="请选择" :disabled="exportMonthDisabled">
					<el-option v-for="item in ExportMonthWay" :key="item.id" :label="item.method" :value="item.id"
						:disabled="item.disabled">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="状  态:" class="exportStyle" prop="status">
				<el-select v-model="jiaofeiExportMonth.status">
					<el-option v-for="item in ExportMonthStatus" :key="item.id" :label="item.status" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
		</el-form>

		<template #footer>
			<span class="dialog-footer">
				<el-button @click="jiaofeiImportModelMonth = false">取 消</el-button>
				<el-button @click="submitMonth" type="primary">生成</el-button>
			</span>
		</template>
	</el-dialog>

	<!-- 现金缴费单独弹出 -->

	<el-dialog :title="'线下现金缴费'" v-model="cashModel">
		<el-form :model="cashForm" ref="cashform" :rules="cashRules" label-width="100px"
			:validate-on-rule-change="true">
			<el-form-item label="房号:" class="cashStyle">
				<el-input v-model="cashForm.house" disabled="true"></el-input>
			</el-form-item>
			<el-form-item label="月份:" class="cashStyle">
				<el-input v-model="cashForm.month" disabled="true"></el-input>
			</el-form-item>
			<el-form-item label="应缴费用:" prop="count" class="cashStyle">
				<el-input v-model="cashForm.count" disabled="true"></el-input>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="cashModel = false">取 消</el-button>
				<el-button @click="cashSend" type="primary">提交</el-button>
			</span>
		</template>
	</el-dialog>

	<!-- 手动录入预缴费 -->
	<el-dialog :title="'手动录入预缴费'" v-model="shoudongYujfModel">
		<el-form label-width="100px">
			<el-form-item label="旧预缴费用:" class="cashStyle">
				<el-input v-model="houseCurrent.yj_fee" disabled="true"></el-input>
			</el-form-item>
			<el-form-item label="新预缴费用:" class="cashStyle">
				<el-input v-model="xinYujf"></el-input>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="shoudongYujfModel = false">取 消</el-button>
				<el-button @click="shoudongyjfSend" type="primary">保存</el-button>
			</span>
		</template>
	</el-dialog>

	<!-- 代扣记录导出 -->
	<el-dialog :title="'代扣记录导出'" v-model="dkexportModel">
		<el-form :model="dkexport" label-width="100px" :validate-on-rule-change="true">
			<el-form-item label="日  期:" class="exportStyle">
				<el-date-picker v-model="dkexport.date" type="month" placeholder="选择日期"></el-date-picker>
			</el-form-item>
			<el-form-item label="选择方式:" class="exportStyle">
				<el-select v-model="dkexport.way" placeholder="请选择">
					<el-option v-for="item in dkexportway" :key="item.id" :label="item.method" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
		</el-form>

		<template #footer>
			<span class="dialog-footer">
				<el-button @click="dkexportModel = false">取 消</el-button>
				<el-button @click="submitDkjilu" type="primary">生成</el-button>
			</span>
		</template>
	</el-dialog>

	<div id="printContent" v-show="true" ref="print" class="tableP">
		<p>坤鹏物业收款结算单</p>

		<table border="1" cellspacing="0" bordercolor="#eee" cellpadding="0" width="100%">
			<tr>
				<td>姓名:</td>
				<td colspan="6">{{ houseCurrent.user_name }}</td>
			</tr>
			<tr>
				<td>房号:</td>
				<td colspan="2">{{ houseCurrent.roomName }}</td>
				<td>单据号:</td>
				<td colspan="3">{{ danju }}</td>
			</tr>
			<tr>
				<td>面积:</td>
				<td colspan="2">{{ houseCurrent.floorSpace }} 平方米</td>
				<td>收款日期:</td>
				<td colspan="3">{{ houseCurrent.pay_time }}</td>
			</tr>
			<tr>
				<th>序号</th>
				<th width="20%">收费项目</th>
				<th>缴费所属时间</th>
				<th>单价</th>
				<th>数量</th>
				<th>金额</th>
				<th width="30%">备注</th>
			</tr>
			<tr>
				<td>1</td>
				<td>物业管理费</td>
				<td>{{ houseCurrent.month }}</td>
				<td>{{ printData.wuyeguanlifei }}</td>
				<td>{{ houseCurrent.floorSpace }}</td>
				<td>
					{{
            printData.wuyeguanlifei * houseCurrent.floorSpace +
            houseCurrent.wood_fee
          }}
				</td>
				<td v-show="houseCurrent.wood_fee">
					柴火房管理费:{{ houseCurrent.wood_fee }}
				</td>
			</tr>
			<tr>
				<td>2</td>
				<td>维修基金</td>
				<td>{{ houseCurrent.month }}</td>
				<td>{{ printData.weixiujijin }}</td>
				<td>{{ houseCurrent.floorSpace }}</td>
				<td>
					{{ (printData.weixiujijin * houseCurrent.floorSpace).toFixed(2) }}
				</td>
				<td></td>
			</tr>
			<tr>
				<td>3</td>
				<td>公摊电费</td>
				<td>{{ houseCurrent.month }}</td>
				<td>{{ printData.gongtandianfei }}</td>
				<td>1</td>
				<td>{{ printData.gongtandianfei }}</td>
				<td>{{printData.fee_note}}</td>
			</tr>
			<tr>
				<td>4</td>
				<td>代收卫生费</td>
				<td>{{ houseCurrent.month }}</td>
				<td>{{ printData.weishengfei }}</td>
				<td>1</td>
				<td>{{ printData.weishengfei }}</td>
				<td></td>
			</tr>
			<tr>
				<td>5</td>
				<td>水费</td>
				<td>{{ houseCurrent.month }}</td>
				<td>{{ printData.shuifei }}</td>
				<td>{{ printData.waterNum }}</td>
				<td>{{ (printData.shuifei * printData.waterNum).toFixed(2) }}</td>
				<td></td>
			</tr>
			<tr>
				<td></td>
				<td>上期读数</td>
				<td>{{ printData.lastWater }}</td>
				<td>本期读数</td>
				<td>{{ printData.currentWater }}</td>
				<td></td>
				<td></td>
			</tr>
			<tr>
				<td>6</td>
				<td>收款方式</td>
				<td>
					{{
            houseCurrent.transaction_id.length > 10
              ? "微信"
              : houseCurrent.transaction_id
          }}
				</td>
				<td></td>
				<td>合计金额</td>
				<td>{{ houseCurrent.totalHe_fee }}</td>
				<td></td>
			</tr>
			<tr>
				<td>7</td>
				<td colspan="3"></td>
				<td>冲销金额</td>
				<td>
					{{
            houseCurrent.yj_fee +
            houseCurrent.zs_fee +
            houseCurrent.dk_wuye_fee +
            houseCurrent.dk_water_fee
          }}
				</td>
				<td>
					<span v-show="houseCurrent.dk_wuye_fee">代扣物业费:{{ houseCurrent.dk_wuye_fee }}</span>
					<span v-show="houseCurrent.dk_water_fee">代扣水费:{{ houseCurrent.dk_water_fee }}</span>
				</td>
			</tr>
			<tr>
				<td colspan="5">
					合计人民币(大写): {{ houseCurrent.daxie_totalHe_fee }}
				</td>
				<td>实收金额:</td>
				<td>￥{{ houseCurrent.totalHe_fee-(houseCurrent.yj_fee + houseCurrent.zs_fee + houseCurrent.dk_wuye_fee + houseCurrent.dk_water_fee)}}</td>
			</tr>
		</table>

		<div class="footer">
			<div class="danwei">
				<span></span>
				<span>丹霞新城坤鹏物业服务中心</span>
			</div>
			<div class="dianhua">
				<span></span>
				<span>0751-6292688/6292588</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		number2Cn
	} from "../utils/number2cn";
	import {
		computed,
		reactive,
		ref,
		watch
	} from "vue";
	import {
		loupan
	} from "../http/information";
	import axios from "axios";
	import printJs from 'print-js'
	import {
		ElMessageBox
	} from "element-plus";
	import fileDownload from "js-file-download";
	import {
		useStore
	} from "vuex";
	import {
		payList,
		postJiaofeijilu,
		jiaofeiSend,
		postJiaofeiYue,
		postJfDay,
		postjiaofeifabu,
		cashPayment,
		getWater,
		getPrice,
		getDanju,
		postShoudongyjf,
		dkjiluExport,
		QTexport,
	} from "../http/pay";
	import {
		ElMessage
	} from "element-plus";
	import {
		readAccount
	} from "../utils/localStorage";

	export default {
		name: "Pay",
		computed:{
			//管理员 
			Permission1:function () {
				return (this.store.state.account || readLocalAccount) == 'admin';
				
				//console.log('account Permission1:'+this.store.state.account)
				//console.log('account Permission1:'+(this.store.state.account=='admin'))
				//return this.store.state.account=='admin';
			},
			//管理员  前台
			Permission2:function () {

					if(this.Permission1){
						return true
					}else{
						return (this.store.state.account || readLocalAccount) == 'rhdx';
					}
			},
		
			//客服
			Permission3:function () {
				if(!this.Permission2)
					return (this.store.state.account || readLocalAccount) == 'kefu';
				return false;
			},
			Permission1Dis:function(){
				return  !this.Permission1;
			},
			Permission2Dis:function(){
				return  !this.Permission2;
			},
		},
		setup() {
			const qiantaiExportMonthWay = reactive([{
					id: "yjFree",
					method: "预缴费"
				},
				{
					id: "zsFree",
					method: "赠送交费"
				},
				{
					id: "yjWuYeFree",
					method: "预交物业费"
				},
			]);
			const qiantaiModel = ref(false)
			const qiantaiRules = reactive({
				qiantaiMonth: [{
					required: "true",
					message: "必填",
					trigger: ["blur"]
				}],
			})
			const qiantaiform = ref(null)
			const qiantaiForm = reactive({
				qiantaiMonth: new Date(),
				way: 'yjFree'
			})
			const readLocalAccount = readAccount();
			const store = useStore();
			const dkexport = reactive({
				date: new Date(),
				way: "all",
			});
			const dkexportway = reactive([{
					id: "all",
					method: "所有代扣记录"
				},
				{
					id: "success",
					method: "代扣成功记录"
				},
				{
					id: "error",
					method: "代扣失败记录"
				},
				{
					id: "other",
					method: "代扣未缴费记录"
				},
			]);
			const dkexportModel = ref(false);
			const shoudongYujfModel = ref(false);
			const xinYujf = ref("");
			const orderinput = ref("");
			const printData = reactive({
				currentWater: "",
				lastWater: "",
				wuyeguanlifei: "",
				weixiujijin: "",
				gongtandianfei: "",
				weishengfei: "",
				shuifei: "",
				waterNum: "",
				fee_note:""
			});
			const print = ref(null);
			const exportMonthDisabled = ref(false);
			const ExportMonthForm = ref(null);
			const jiaofeiImportModelMonth = ref(false);
			const ExportDayForm = ref(null);
			const exportDayRules = reactive({
				exportDay: [{
					required: "true",
					message: "必填",
					trigger: ["blur"]
				}],
				way: [{
					required: "true",
					message: "必填",
					trigger: ["blur"]
				}],
				status: [{
					required: "true",
					message: "必填",
					trigger: ["blur"]
				}],
			});
			const exportMonthRules = reactive({
				exportMonth: [{
					required: "true",
					message: "必填",
					trigger: ["blur"]
				}],
				status: [{
					required: "true",
					message: "必填",
					trigger: ["blur"]
				}],
			});
			const ExportDayStatus = reactive([{
				id: 2,
				status: "已缴费"
			}]);
			const ExportMonthStatus = reactive([{
					id: 1,
					status: "未缴费"
				},
				{
					id: 2,
					status: "已缴费"
				},
			]);
			const ExportDayWay = reactive([{
					id: 1,
					method: "所有"
				},
				{
					id: 2,
					method: "微信缴费"
				},
				{
					id: 3,
					method: "现金缴费"
				},
				{
					id: 4,
					method: "其他缴费方式"
				},
			]);
			const ExportMonthWay = reactive([{
					id: 1,
					method: "所有"
				},
				{
					id: 2,
					method: "微信缴费"
				},
				{
					id: 3,
					method: "现金缴费"
				},
				{
					id: 4,
					method: "其他缴费方式"
				},
				{
					id: "",
					method: "不可选择",
					disabled: true
				},
			]);
			const jiaofeiExportDay = reactive({
				exportDay: "",
				way: "",
				status: "",
			});
			const jiaofeiExportMonth = reactive({
				exportMonth: "",
				way: 1,
				status: "",
			});
			const cashRules = reactive({
				count: [{
					required: "true",
					message: "金额必填",
					trigger: ["blur"]
				}],
			});
			const cashform = ref(null);
			const cashForm = reactive({
				house: "",
				month: "",
				count: 0,
			});
			const cashModel = ref(false);
			const importDatePiliang = ref("");
			const fileImportModel = ref(false);
			const jiaofeiImportModelDay = ref(false);
			const jiaofeijilu = ref(new Date());
			const importTitle = ref("");
			const jiaofeiImportModel = ref(false);
			const importDate = ref("");
			const currentPage1 = ref("1");
			const houseNum = ref("");
			const tableData = reactive([]);
			const totalCount = ref(0);
			const resData = reactive([{
				id: "",
				building_name: "不限"
			}]);
			const statusData = reactive([{
					id: "",
					name: "不限"
				},
				{
					id: 0,
					name: "未缴费(微信不显示)"
				},
				{
					id: 1,
					name: "已发布(微信上显示)"
				},
				{
					id: 2,
					name: "已缴费"
				},
				{
					id: 3,
					name: "已绑定"
				},
			]);
			const houseCurrent = reactive({
				id: "",
				month: "",
				totalfee: "",
				roomName: "",
				transaction_id: "",
				totalHe_fee: "",
				floorSpace: 0,
				status: "",
				user_name: "",
				pay_time: "",
				daxie_totalHe_fee: "",
				wood_fee: "",
				yj_fee: "",
				zs_fee: "",
				dk_wuye_fee: "",
				dk_water_fee: "",
			});
			const form = reactive({
				name: "",
				house_name: "",
				user_name: "",
				date: new Date(),
				status: "",
			});

			// 单据

			const danju = computed(() => {
				let one = houseCurrent.transaction_id.length > 10 ? "wx" : "xj";
				let arrMonth = houseCurrent.month.split("-");
				let strEnd = "";
				let strFirst = "";
				let arrFir = "";
				let arrEnd = "";
				if (houseCurrent.pay_time) {
					strEnd = houseCurrent.pay_time.slice(11);
					strFirst = houseCurrent.pay_time.slice(0, 10);
					arrFir = strFirst.split("-");
					arrEnd = strEnd.split(":");
					return (
						one +
						houseCurrent.id +
						arrMonth[0] +
						arrMonth[1] +
						arrFir[0] +
						arrFir[1] +
						arrFir[2] +
						arrEnd[0] +
						arrEnd[1] +
						arrEnd[2]
					);
				} else {
					return "";
				}
			});
			loupan().then((res) => {
				let data = res.data;
				let state = data.state;
				if (state == 0) {
					let resultData = data.data;
					for (let i = 0; i < resultData.length; i++) {
						let refData = {
							id: resultData[i].id,
							building_name: resultData[i].building_name,
						};
						resData.push(refData);
					}
				} else if (state == 1) {}
			});
			//提交查询

			function submitForm() {
				getPayList(1);
			}
			//获取列表
			function getPayList(page) {
				let endDate = "";
				let date = form.date;
				if (date) {
					let YYYY = date.getFullYear();
					let MM = date.getMonth() + 1;
					if (MM > 9) {
						endDate = YYYY + "-" + MM;
					} else {
						endDate = YYYY + "-" + "0" + MM;
					}
				}
				payList(
						page,
						50,
						form.name,
						form.house_name,
						endDate,
						form.user_name,
						form.status
					)
					.then((res) => {
						let data = res.data;
						let state = data.state;
						let resultData = data.data.rows;
						totalCount.value = data.data.total;
						if (state == 0) {
							tableData.length = 0;
							for (let i = 1; i <= resultData.length; i++) {
								let resStatus = "";
								if (resultData[i - 1].status == 0) {
									resStatus = "未缴费";
								} else if (resultData[i - 1].status == 1) {
									resStatus = "已发布";
								} else if (resultData[i - 1].status == 2) {
									resStatus = "已缴费";
								}
								let refData = {
									index: i + (currentPage1.value - 1) * 50,
									id: resultData[i - 1].id,
									room_name: resultData[i - 1].room_name,
									user_name: resultData[i - 1].user_name,
									month: resultData[i - 1].month,
									property_fee: resultData[i - 1].property_fee,
									wood_fee: resultData[i - 1].wood_fee,
									fee: resultData[i - 1].fee,
									health_costs: resultData[i - 1].health_costs,
									maintenance_fee: resultData[i - 1].maintenance_fee,
									water: +resultData[i - 1].water,
									//total_fee:1,
									totalHe_fee: (
										resultData[i - 1].property_fee +
										resultData[i - 1].wood_fee +
										resultData[i - 1].fee +
										resultData[i - 1].health_costs +
										resultData[i - 1].maintenance_fee +
										resultData[i - 1].water
									).toFixed(2),
									yj_fee: resultData[i - 1].yj_fee,
									zs_fee: resultData[i - 1].zs_fee,
									dk_wuye_fee: resultData[i - 1].dk_wuye_fee,
									dk_water_fee: resultData[i - 1].dk_water_fee,
									status: resStatus,
									total_fee: +resultData[i - 1].total_fee,
									transaction_id: resultData[i - 1].transaction_id,
									pay_time: resultData[i - 1].pay_time,
									create_time: resultData[i - 1].create_time,
								};
								tableData.push(refData);
							}
						}
					})
					.catch((err) => {
						ElMessage.error("请求失败");
						console.log(err);
					});
			}
			getPayList(1);
			//选中触发

			//代扣表格
			function importDaikou(command) {
				if (command == "a") {
					window.location.href =
						"https://rhkpwy.com/ExcelFile/GetExcelFile?name=daikou";
				} else if (command == "b") {
					importTitle.value = "银行代扣失败导入";
					fileImportModel.value = true;
				} else if (command == "c") {
					window.location.href =
						"https://rhkpwy.com/ExcelFile/GetExcelFile?name=dkchenggong";
				} else if (command == "d") {
					importTitle.value = "银行代扣成功导入";
					fileImportModel.value = true;
				} else if (command == "e") {
					dkexportModel.value = true;
				}
			}

			//现金缴费
			function importXianjin(command) {
				if (command == "a") {
					window.location.href =
						"https://rhkpwy.com/ExcelFile/GetExcelFile?name=xianjinfee";
				} else if (command == "b") {
					importTitle.value = "现金缴费数据批量导入";
					fileImportModel.value = true;
				} else if (command == "c") {
					if (houseCurrent.id) {
						if (houseCurrent.transaction_id) {
							ElMessage.warning("此记录已经缴费,不需要重复缴费!");
						} else {
							cashForm.count =(
								houseCurrent.totalHe_fee -
								(houseCurrent.yj_fee +
									houseCurrent.zs_fee +
									houseCurrent.dk_wuye_fee +
									houseCurrent.dk_water_fee)).toFixed(2);
							cashModel.value = true;
						}
					} else {
						ElMessage.warning("请先选择一项");
					}
				}
			}

			//预交物业费

			function importYujiaoWuye(command) {
				if (command == "a") {
					window.location.href =
						"https://rhkpwy.com/ExcelFile/GetExcelFile?name=yujiaofee";
				} else if (command == "b") {
					importTitle.value = "预交物业费批量导入";
					fileImportModel.value = true;
				}
			}
			//重置缴费
			function importResrtFree(command) {
				if (command == "a") {
					window.location.href =
						"https://rhkpwy.com/ExcelFile/GetExcelFile?name=restMonthers";
				} else if (command == "b") {
					importTitle.value = "重置缴费导入";
					fileImportModel.value = true;
				}
			}
			//水费导入

			function importWater(command) {
				if (command == "a") {
					window.location.href =
						"https://rhkpwy.com/ExcelFile/GetExcelFile?name=water";
				} else if (command == "b") {
					importTitle.value = "水费批量导入并发布(覆盖模式)";
					fileImportModel.value = true;
				} else if (command == "c") {
					importTitle.value = "水费批量导入(相加模式,正加负减，发布后)";
					fileImportModel.value = true;
				}
				
			}

			//赠送物业费

			function importZengsongWuye(command) {
				if (command == "a") {
					window.location.href =
						"https://rhkpwy.com/ExcelFile/GetExcelFile?name=zengsongfee";
				} else if (command == "b") {
					importTitle.value = "赠送物业费批量导入";
					fileImportModel.value = true;
				}
			}

			//与缴费

			function importYujiao(command) {
				if (command == "a") {
					window.location.href =
						"https://rhkpwy.com/ExcelFile/GetExcelFile?name=yujiaofee1";
				} else if (command == "b") {
					importTitle.value = "预交费批量导入(发布)";
					fileImportModel.value = true;
				} else if (command == "c") {
					importTitle.value = "预交费批量导入(清空)";
					fileImportModel.value = true;
				} else if (command == "d") {
					if (houseCurrent.id) {
						if (houseCurrent.status == "已发布") {
							shoudongYujfModel.value = true;
						} else {
							ElMessage.error("不能手动预缴费");
						}
					} else {
						ElMessage.warning("请先选择一项");
					}
				}
			}

			//通知发送

			function paySend(command) {
				switch (command) {
					case "a":
						if (form.date) {
							ElMessageBox.confirm("您确认要发送当前选择的月份吗", "提示", {
									confirmButtonText: "确认",
									cancelButtonText: "取消",
									type: "warning",
								})
								.then(() => {
									jiaofeiSend(formatDateMonth(form.date), "notifySend").then(
										(res) => {
											let data = res.data;
											let state = data.state;
											if (state == 0) {
												ElMessage.success(data.message);
											} else {
												ElMessage.error("发送失败");
											}
										}
									);
								})
								.catch(() => {
									console.log("取消了");
								});
						} else {
							ElMessage.warning("请选择月份");
						}
						break;
					case "b":
						if (form.date) {
							ElMessageBox.confirm("您确认要发送当前选择的月份吗", "提示", {
									confirmButtonText: "确认",
									cancelButtonText: "取消",
									type: "warning",
								})
								.then(() => {
									jiaofeiSend(formatDateMonth(form.date), "paySend").then(
										(res) => {
											let data = res.data;
											let state = data.state;
											if (state == 0) {
												ElMessage.success(data.message);
											} else {
												ElMessage.error("发送失败");
											}
										}
									);
								})
								.catch(() => {
									console.log("取消了");
								});
						} else {
							ElMessage.warning("请选择月份");
						}
						break;
					case "c":
						if (form.date) {
							ElMessageBox.confirm("您确认要发送当前选择的月份吗", "提示", {
									confirmButtonText: "确认",
									cancelButtonText: "取消",
									type: "warning",
								})
								.then(() => {
									jiaofeiSend(
										formatDateMonth(form.date),
										"feeDeductionFailed"
									).then((res) => {
										let data = res.data;
										let state = data.state;
										if (state == 0) {
											ElMessage.success(data.message);
										} else {
											ElMessage.error("发送失败");
										}
									});
								})
								.catch(() => {
									console.log("取消了");
								});
						} else {
							ElMessage.warning("请选择月份");
						}
						break;
				}
			}

			//生成缴费记录
			function exportJiaofei() {
				importTitle.value = "生成物业缴费记录";
				jiaofeiImportModel.value = true;
			}

			// 缴费发布

			function jiaofeifabu(command) {
				if (command == "a") {
					ElMessageBox.confirm(
							"发布前请确保已经上传了水费，预缴物业费和赠送物业费，发布后将不能更新。",
							"提示", {
								confirmButtonText: "确认",
								cancelButtonText: "取消",
								type: "warning",
							}
						)
						.then(() => {
							importTitle.value = "批量发布物业缴费记录";
							jiaofeiImportModel.value = true;
						})
						.catch(() => {});
				}
			}

			// 提交月份产生的记录

			function submit() {
				if (importTitle.value == "生成物业缴费记录") {
					postJiaofeijilu(formatDateMonth(jiaofeijilu.value)).then((res) => {
						let data = res.data;
						let state = data.state;
						if (state == 0) {
							jiaofeiImportModel.value = false;
							ElMessage.success("生成成功");
							getPayList(1);
						} else if (state == 1) {
							jiaofeiImportModel.value = false;
							ElMessage.error(data.message);
						}
					});
				} else if (importTitle.value == "批量发布物业缴费记录") {
					if (importDatePiliang.value) {
						postjiaofeifabu(formatDateMonth(importDatePiliang.value))
							.then((res) => {
								let data = res.data;
								let state = data.state;
								if (state == 0) {
									ElMessage.success(data.message);
									jiaofeiImportModel.value = false;
									getPayList(1);
								} else {
									ElMessage.error(data.message);
									jiaofeiImportModel.value = false;
								}
							})
							.catch((err) => {
								ElMessage.error("发布失败");
							});
					} else {
						ElMessage.warning("请选择时间");
					}
				}
			}

			//导出天的记录
			function submitDay() {
				ExportDayForm.value.validate((valid) => {
					if (valid) {
						axios
							.get(
								"https://rhkpwy.com/PayManagement/GetPrintDayCount", {
									params: {
										day: formatDate(jiaofeiExportDay.exportDay),
										payType: jiaofeiExportDay.way,
										status: jiaofeiExportDay.status,
									},
								}, {
									responseType: "arraybuffer"
								}
							)
							.then((res) => {
								let http = "https://rhkpwy.com/PayManagement/GetPrintDayCount";
								let day = formatDate(jiaofeiExportDay.exportDay);
								window.location.href =
									http +
									"?" +
									"day=" +
									day +
									"&" +
									"payType=" +
									jiaofeiExportDay.way +
									"&" +
									"status=" +
									jiaofeiExportDay.status;
								jiaofeiImportModelDay.value = false;
							});
					}
				});
			}

			// 导出月的记录
			function submitMonth() {
				ExportMonthForm.value.validate((valid) => {
					if (valid) {
						let http = "https://rhkpwy.com/PayManagement/GetOrderspayGson";
						let month = formatDateMonth(jiaofeiExportMonth.exportMonth);
						window.location.href =
							http +
							"?" +
							"month=" +
							month +
							"&" +
							"payType=" +
							jiaofeiExportMonth.way +
							"&" +
							"status=" +
							jiaofeiExportMonth.status;
						jiaofeiImportModelMonth.value = false;
					}
				});
			}

			// 下载流文件

			//日期格式转换

			function formatDate(date) {
				let year = new Date(date).getFullYear();
				let month = new Date(date).getMonth() + 1;
				let day = new Date(date).getDate();
				if (month < 10) {
					return year + "-" + "0" + month + "-" + day;
				} else {
					return year + "-" + month + "-" + day;
				}
			}
			//  日期格式转换
			function formatDateMonth(date) {
				let year = new Date(date).getFullYear();
				let month = new Date(date).getMonth() + 1;
				if (month < 10) {
					return year + "-" + "0" + month;
				} else {
					return year + "-" + month;
				}
			}

			//已缴费导出

			function exportYijf(command) {
				if (command == "a") {
					jiaofeiImportModelMonth.value = true;
				} else if (command == "b") {
					jiaofeiImportModelDay.value = true;
				}
			}

			//文件导入
			function uploadFile(e) {
				let file = e.target.files[0];
				let formdata = new FormData();
				let importUrl = "";
				formdata.append("file", file);
				if (importTitle.value == "水费批量导入并发布(覆盖模式)") {
					importUrl =
						"https://rhkpwy.com/PayManagement/BatchExcelImport?method=waterImportCover";
				} else if(importTitle.value == "水费批量导入(相加模式,正加负减，发布后)"){
					importUrl =
					"https://rhkpwy.com/PayManagement/BatchExcelImport?method=waterImport";
				}
				else if (importTitle.value == "预交物业费批量导入") {
					importUrl =
						"https://rhkpwy.com/PayManagement/BatchExcelImport?method=yujiaoImport";
				} else if (importTitle.value == "赠送物业费批量导入") {
					importUrl =
						"https://rhkpwy.com/PayManagement/BatchExcelImport?method=zengsongImport";
				} else if (importTitle.value == "预交费批量导入(发布)") {
					importUrl =
						"https://rhkpwy.com/PayManagement/BatchExcelImport?method=yujiaoImportPublishAfter";
				} else if (importTitle.value == "预交费批量导入(清空)") {
					importUrl =
						"https://rhkpwy.com/PayManagement/BatchExcelImport?method=yujiaoImportClear";
				} else if (importTitle.value == "银行代扣失败导入") {
					importUrl =
						"https://rhkpwy.com/PayManagement/BatchExcelImport?method=bankImport";
				} else if (importTitle.value == "现金缴费数据批量导入") {
					importUrl =
						"https://rhkpwy.com/PayManagement/BatchExcelImport?method=xianjinImport";
				} else if (importTitle.value == "银行代扣成功导入") {
					importUrl =
						"https://rhkpwy.com/PayManagement/BatchExcelImport?method=bankSuccessImport";
				} else if (importTitle.value == "公摊电费导入") {
					importUrl =
						"https://rhkpwy.com/PayManagement/BatchExcelImport?method=GongTanFeeImport";
				}
				else if(importTitle.value == "重置缴费导入"){
					importUrl =
						"https://rhkpwy.com/PayManagement/BatchExcelImport?method=restMonthers";
				}

				axios.post(importUrl, formdata).then((res) => {
					let data = res.data;
					let state = data.state;
					if (state == 0) {
						fileImportModel.value = false;
						ElMessage.success("导入成功");
						getPayList(1);
					} else if (state == 1) {
						ElMessage.error(data.message);
						fileImportModel.value = false;
					}
				});
			}

			//选中当前

			function handleChange(val) {
				houseCurrent.id = val.id;
				houseCurrent.month = val.month;
				houseCurrent.totalfee = val.total_fee;
				houseCurrent.roomName = val.room_name;
				houseCurrent.transaction_id = val.transaction_id;
				houseCurrent.totalHe_fee = val.totalHe_fee;
				cashForm.house = val.room_name;
				cashForm.month = val.month;
				houseCurrent.floorSpace = val.floorSpace;
				houseCurrent.status = val.status;
				houseCurrent.user_name = val.user_name;
				houseCurrent.pay_time = val.pay_time;
				houseCurrent.wood_fee = val.wood_fee;
				houseCurrent.yj_fee = val.yj_fee;
				houseCurrent.zs_fee = val.zs_fee;
				houseCurrent.dk_wuye_fee = val.dk_wuye_fee;
				houseCurrent.dk_water_fee = val.dk_water_fee;
				houseCurrent.daxie_totalHe_fee = number2Cn(String(val.totalHe_fee));
				// getPrice(houseCurrent.id).then((res) => {
				//   let data = res.data;
				//   let state = data.state;
				//   if (state == 0) {
				//     printData.wuyeguanlifei = data.data.wuyeguanlifei;
				//     printData.weixiujijin = data.data.weixiujijin;
				//     printData.gongtandianfei = data.data.gongtandianfei;
				//     printData.weishengfei = data.data.weishengfei;
				//     printData.shuifei = data.data.shuifei;
				//     printData.waterNum = data.data.waterNum;
				//     houseCurrent.floorSpace = data.data.floorSpace;
				//   }
				// });
				// getWater(houseCurrent.id, houseCurrent.month).then((res) => {
				//   let data = res.data;
				//   let state = data.state;
				//   console.log(data);
				//   if (state == 0) {
				//     if (data.data) {
				//       printData.currentWater = data.data.currentWater;
				//       printData.lastWater = data.data.lastWater;
				//     }
				//   } else {
				//     ElMessage.error(data.message);
				//   }
				// });
			}
			//现金缴费发布

			function cashSend() {
				cashform.value.validate((valid) => {
					if (valid) {
						if (1) {
							ElMessageBox.confirm("确定提交吗？", "提示", {
								confirmButtonText: "确认",
								cancelButtonText: "取消",
								type: "warning",
							}).then(() => {
								cashPayment(
										houseCurrent.id,
										cashForm.month,
										cashForm.count,
										cashForm.house
									)
									.then((res) => {
										let data = res.data;
										let state = data.state;
										if (state == 0) {
											ElMessage.success("缴费成功");
											cashModel.value = false;
											getPayList(1);
										} else if (state == 1) {
											ElMessage.error(data.message);
											cashModel.value = false;
										}
									})
									.catch((err) => {
										ElMessage.error("缴费失败");
									});
							});
						} else {
							// cashPayment(
							//   houseCurrent.id,
							//   cashForm.month,
							//   cashForm.count,
							//   cashForm.house
							// )
							//   .then((res) => {
							//     let data = res.data;
							//     let state = data.state;
							//     if (state == 0) {
							//       ElMessage.success("缴费成功");
							//       cashModel.value = false;
							//       getPayList(1);
							//     } else if (state == 1) {
							//       ElMessage.error(data.message);
							//       cashModel.value = false;
							//     }
							//   })
							//   .catch((err) => {
							//     ElMessage.error("缴费失败");
							//   });
						}
					}
				});
			}

			// 监听导出月的  缴费方式
			watch(
				() => jiaofeiExportMonth.status,
				(newVal) => {
					if (newVal == 1) {
						jiaofeiExportMonth.way = "";
						exportMonthDisabled.value = true;
					} else {
						exportMonthDisabled.value = false;
						jiaofeiExportMonth.way = 1;
					}
				}
			);
			//翻页
			function handlePagination(val) {
				currentPage1.value = val;
				getPayList(val);
			}

			//打印

			function printFn() {
				if (houseCurrent.id) {
					if (houseCurrent.status == "未缴费") {
						ElMessage.error("未缴费不能打印");
					} else {
						// let oldstr = document.body.innerHTML;
						getPrice(houseCurrent.id).then((res) => {
							let data = res.data;
							let state = data.state;
							if (state == 0) {
								printData.wuyeguanlifei = data.data.wuyeguanlifei;
								printData.weixiujijin = data.data.weixiujijin;
								printData.gongtandianfei = data.data.gongtandianfei;
								printData.weishengfei = data.data.weishengfei;
								printData.shuifei = data.data.shuifei;
								printData.waterNum = +data.data.waterNum;
								houseCurrent.floorSpace = data.data.floorSpace;
								printData.fee_note = data.data.fee_note;

								if (data.data.WaterInfo == "1" || data.data.WaterInfo == "1.0") {
									printData.currentWater = data.data.CurrentWater;
									printData.lastWater = data.data.LastWater;
								}
							}
							setTimeout(() => {
								// let newstr = document.getElementById("printContent").innerHTML;
								// document.body.innerHTML = newstr;
								// window.print();
								// location.reload();
								const style = '@page {  } ' +
									'@media print { .tableP h2{margin: 0 auto} .red{ color:#f00} .green{color:green}';
								printJs({
									printable: 'printContent',
									type: 'html',
									style: style,
									css: "./print.css"

								})
							});
						});
					}
				} else {
					ElMessage.warning("请选择一项");
				}
			}

			function search() {
				if (orderinput.value.trim()) {
					getDanju(orderinput.value).then((res) => {
						let data = res.data;
						let state = data.state;
						let resultData = data.data.rows;

						if (state == 0) {
							totalCount.value = data.data.total;
							tableData.length = 0;
							for (let i = 1; i <= resultData.length; i++) {
								let resStatus = "";
								if (resultData[i - 1].status == 0) {
									resStatus = "未缴费";
								} else if (resultData[i - 1].status == 1) {
									resStatus = "已发布";
								} else if (resultData[i - 1].status == 2) {
									resStatus = "已缴费";
								}
								let refData = {
									index: i + (currentPage1.value - 1) * 50,
									id: resultData[i - 1].id,
									room_name: resultData[i - 1].room_name,
									user_name: resultData[i - 1].user_name,
									month: resultData[i - 1].month,
									property_fee: resultData[i - 1].property_fee,
									wood_fee: resultData[i - 1].wood_fee,
									fee: resultData[i - 1].fee,
									health_costs: resultData[i - 1].health_costs,
									maintenance_fee: resultData[i - 1].maintenance_fee,
									water: +resultData[i - 1].water,
									//total_fee:1,
									totalHe_fee: (
										resultData[i - 1].property_fee +
										resultData[i - 1].wood_fee +
										resultData[i - 1].fee +
										resultData[i - 1].health_costs +
										resultData[i - 1].maintenance_fee +
										resultData[i - 1].water
									).toFixed(2),
									yj_fee: resultData[i - 1].yj_fee,
									zs_fee: resultData[i - 1].zs_fee,
									dk_wuye_fee: resultData[i - 1].dk_wuye_fee,
									dk_water_fee: resultData[i - 1].dk_water_fee,
									status: resStatus,
									total_fee: +resultData[i - 1].total_fee,
									transaction_id: resultData[i - 1].transaction_id,
									pay_time: resultData[i - 1].pay_time,
									create_time: resultData[i - 1].create_time,
									floorSpace: resultData[i - 1].floorSpace,
								};
								tableData.push(refData);
							}
						} else {
							ElMessage.error("查无此单据号");
						}
					});
				} else {
					ElMessage.warning("请输入单据号");
				}
			}
			// 手动预缴费
			function shoudongyjfSend() {
				if (xinYujf.value.trim()) {
					postShoudongyjf(houseCurrent.id, houseCurrent.yj_fee, xinYujf.value)
						.then((res) => {
							let data = res.data;
							let state = data.state;
							if (state == 0) {
								ElMessage.success("保存成功");
								shoudongYujfModel.value = false;
								getPayList(1);
							} else {
								ElMessage.error(data.message);
							}
						})
						.catch((err) => {
							ElMessage.error("保存错误");
						});
				} else {
					ElMessage.error("请输入新预缴费用");
				}
			}

			// 代扣记录导出

			function submitDkjilu() {
				if (dkexport.date) {
					//  dkjiluExport(formatDateMonth(dkexport.date),dkexport.way).then(res=>{
					//       window.location.href= res.data
					//  })
					let http = "https://rhkpwy.com/PayManagement/GetDKFile";
					let date = formatDateMonth(dkexport.date);
					window.location.href =
						http + "?" + "month=" + date + "&" + "method=" + dkexport.way;
					dkexportModel.value = false;
				} else {
					ElMessage.warning("请选择时间");
				}
			}

			//前台 导出

			function qiantai() {
				qiantaiModel.value = true
			}

			//前台提交
			function submitQiantai() {
				qiantaiform.value.validate((valid) => {
					if (valid) {
						let http = "https://rhkpwy.com/PayManagement/GetOrdersMonth";
						let date = formatDateMonth(qiantaiForm.qiantaiMonth);
						window.location.href =
							http + "?" + "month=" + date + "&" + "method=" + qiantaiForm.way;
						qiantaiModel.value = false;
					} else {

					}
				})
			}
			// 公摊电费导入
			function importDianfei(command) {
				if (command == 'a') {
					window.location.href =
						"https://rhkpwy.com/ExcelFile/GetExcelFile?name=GongTanFee";
				} else {
					importTitle.value = "公摊电费导入";
					fileImportModel.value = true;
				}
			}

			return {
				importResrtFree,
				importDianfei,
				submitQiantai,
				qiantaiExportMonthWay,
				qiantaiModel,
				qiantaiRules,
				qiantaiform,
				qiantaiForm,
				qiantai,
				readLocalAccount,
				store,
				submitDkjilu,
				dkexportModel,
				dkexportway,
				dkexport,
				shoudongyjfSend,
				shoudongYujfModel,
				xinYujf,
				search,
				orderinput,
				danju,
				printData,
				print,
				printFn,
				submitMonth,
				exportMonthDisabled,
				ExportMonthStatus,
				ExportMonthWay,
				exportMonthRules,
				ExportMonthForm,
				jiaofeiExportMonth,
				jiaofeiImportModelMonth,
				formatDate,
				ExportDayForm,
				exportDayRules,
				ExportDayStatus,
				ExportDayWay,
				jiaofeiExportDay,
				cashSend,
				cashModel,
				cashRules,
				cashform,
				cashForm,
				houseCurrent,
				handleChange,
				importDatePiliang,
				paySend,
				jiaofeifabu,
				fileImportModel,
				uploadFile,
				submitDay,
				jiaofeiImportModelDay,
				exportYijf,
				submit,
				jiaofeijilu,
				importDate,
				exportJiaofei,
				resData,
				form,
				houseNum,
				tableData,
				statusData,
				submitForm,
				totalCount,
				handlePagination,
				currentPage1,
				importDaikou,
				importXianjin,
				importWater,
				importYujiaoWuye,
				importZengsongWuye,
				importYujiao,
				jiaofeiImportModel,
				importTitle,
			};
		},
	};
</script>
<style scoped>
	.el-table /deep/ td {
		color: black;
		font-size: 12px;
		padding: 2px 0px;
	}

	.el-table /deep/ .cell {
		padding: 2px 3px;
	}

	.pay {
		width: 100%;
		height: 100%;
		position: relative;
	}

	.block {
		position: absolute;
		bottom: 0;
	}

	.waring {
		color: red;
		font-size: 16px;
		margin-bottom: 10px;
	}

	/deep/.el-table__body tr.current-row>td {
		background-color: #ffe48d;
	}

	.el-form-item {
		margin-bottom: 0;
	}

	.el-form-item.cashStyle {
		margin-bottom: 20px;
	}

	.exportStyle {
		margin-bottom: 20px;
	}

	.secondRow {
		display: flex;
		justify-content: space-between;
	}

	.secondRow .right {
		padding-right: 150px;
	}
</style>
<style media="print">
	.tableP h2 {
		text-align: center;
		color: red
	}

	.tableP {
		position: absolute;
		left: -1000px;
		top: -1000px;
		margin-top: 1px;
		width: 100%;
		border: 1px solid #000;
		border-collapse: collapse;
	}

	/* div.left {
        margin-left: 20px;
      } */
	.danwei {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		font-size: 20px;
		font-weight: 700;
	}

	.dianhua {
		margin-top: 10px;
		display: flex;
		justify-content: space-between;
		font-size: 20px;
	}

	.footer {
		position: absolute;
		bottom: -80px;
		right: 100px;
	}

	.tableP td {
		padding: 2px;
		text-align: left;
	}

	.tableP th {
		padding: 2px;
		text-align: left;
	}

	.date {
		margin-top: 10px;
	}

	.shangqi {
		margin-left: 100px;
	}
</style>
